// @ts-nocheck
import React from 'react';
import { Router } from '@gatsbyjs/reach-router';
import AppLayout from '../components/layout/AppLayout';
import DocumentPage from '../components/DocumentPage/DocumentPage';

const Document = () => {
  return (
    <AppLayout>
      <Router>
        <DocumentPage path="/documents" />
      </Router>
    </AppLayout>
  );
};
export default Document;
